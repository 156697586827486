import { useNavigate } from "react-router-dom";
import "./App.css";
import { generateMeetingGuid } from "./utilities.ts";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import meeting from "./assets/meetingIris.svg";
import { useState } from "react";

function App() {
  const navigate = useNavigate();
  const [isMeetingUrlValidAndPopulated, setIsMeetingUrlValidAndPopulated] =
    useState(false);
  const [meetingIdEntered, setMeetingIdEntered] = useState("");
  const newMeeting = () => {
    const meetingId = generateMeetingGuid();
    navigate(`/meeting/${meetingId}`, { state: { meetingId } });
  };

  const handleChange = (e) => {
    const meetingId = e.target.value.slice(-36);
    if (meetingId.length === 36) {
      setIsMeetingUrlValidAndPopulated(true);
      setMeetingIdEntered(meetingId);
    } else {
      setMeetingIdEntered("");
      setIsMeetingUrlValidAndPopulated(false);
    }
  };

  const handleNavigateToMeeting = () => {
    if (isMeetingUrlValidAndPopulated && meetingIdEntered.length === 36) {
      navigate(`/meeting/${meetingIdEntered}`, { state: { meetingIdEntered } });
    }
  };

  return (
    <div className="app">
      <div className="wrapper">
        <div className="left">
          <div className="left-inner inner">
            <h2>1-On-1's... done.</h2>

            <img className="left-svg" src={meeting} alt="meeting" />
            <h3>Video calls, with no install.</h3>
          </div>
        </div>
        <div className="right">
          <div className="right-inner inner">
            <div className="controls">
              <button
                className="newMeetingButton controlButton"
                onClick={newMeeting}
              >
                <VideoCallIcon fontSize="large" />
                New meeting
              </button>

              <div className="controlInputWrapper controlButton">
                <ArrowForwardIcon
                  fontSize="large"
                  sx={{
                    color: isMeetingUrlValidAndPopulated
                      ? "#32CD32"
                      : "#d3d3d3",
                  }}
                  onClick={handleNavigateToMeeting}
                />

                <input
                  className="controlInput"
                  placeholder="Enter meeting code"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
