import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.js";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Start from "./start/Start.tsx";
import Meeting from "./meeting/Meeting.tsx";
// import Meeting from "./meeting/Meeting.js";
import * as process from "process";

(window as any).global = window;
(window as any).process = process;
(window as any).Buffer = [];

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/meeting/:meetingId",
    element: <Meeting />,
  },
  {
    path: "/meeting/start",
    element: <Start />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
  // <React.StrictMode>
  <RouterProvider router={router} />
  // </React.StrictMode>
);
