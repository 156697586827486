import { Popover } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import "./popover.css";

interface InviteToMeetingModalProps {
  open: boolean;
  modalAnchorEl: HTMLButtonElement;
  meetingId: string | undefined;
  handleClose: () => void;
}

export const InviteToMeetingModal = ({
  open,
  modalAnchorEl,
  handleClose,
}: InviteToMeetingModalProps) => {
  const currentUrl = window.location.href;
  const handleCopy = () => {
    navigator.clipboard.writeText(currentUrl);
  };
  return (
    <Popover
      id={"meeting-invite-modal"}
      open={open}
      anchorEl={modalAnchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <div className="popover-wrapper">
        <div className="popover-1">
          <h2>Your 1:1 is ready</h2>
          <CloseIcon className="close-icon" onClick={handleClose} />
        </div>

        <div className="meeting-link-body">
          <p>Your meeting link:</p>
          <p>Send this link to who you want a meeting with</p>
          <div className="meeting-link-body">
            <p className="meetingUrl">{currentUrl}</p>
            <button onClick={handleCopy} className="copy-btn">
              Copy
            </button>
          </div>
        </div>
      </div>
    </Popover>
  );
};
