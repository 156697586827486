import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import io from "socket.io-client";
import Peer from "simple-peer";
import "./style.css";
import join from "../assets/join.mp3";
import { DisableVideo } from "./video/DisableVideo.tsx";
import { EnableVideo } from "./video/EnableVideo.tsx";
import { DisableAudio } from "./audio/DisableAudio.tsx";
import { EnableAudio } from "./audio/EnableAudio.tsx";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { InviteToMeetingModal } from "./InviteToMeetingModal.tsx";
import CallEndIcon from "@mui/icons-material/CallEnd";
import raining from "../assets/raining.svg";

const Meeting = () => {
  const { meetingId } = useParams<{ meetingId: string }>();
  const [mySocketId, setMySocketId] = useState("");
  const [callAccepted, setCallAccepted] = useState(false);
  const [isVideoEnabled, setIsVideoEnabled] = useState<boolean>(true);
  const [videoEnabled, setVideoEnabled] = useState<boolean>(true);
  const [audioEnabled, setAudioEnabled] = useState<boolean>(true);
  const [inviteModalOpen, setInviteModalOpen] = useState<boolean>(true);
  const [modalAnchorEl, setModalAnchorEl] = useState<HTMLButtonElement | null>(
    null
  );
  const navigate = useNavigate();
  const myVideoRef = useRef<HTMLVideoElement>(null);
  const userVideoRef = useRef<HTMLVideoElement>(null);
  const connectionRef = useRef<Peer.Instance | null>(null);
  const streamRef = useRef<MediaStream | null>(null);
  const socketRef = useRef<any>(null);
  const mySocketIdRef = useRef(mySocketId);

  const callUser = useCallback((id: string) => {
    if (!streamRef.current) {
      console.error("Stream is not available, retrying...");
      setTimeout(() => callUser(id), 1000); // Retry after 1 second
      return;
    }

    const peer = new Peer({
      initiator: true,
      trickle: false,
      stream: streamRef.current,
    });

    peer.on("signal", (data) => {
      socketRef.current.emit("callUser", {
        userToCall: id,
        signalData: data,
        from: mySocketIdRef.current,
      });
    });

    peer.on("stream", (remoteStream) => {
      console.log("Receiving remote stream");
      if (userVideoRef.current) {
        userVideoRef.current.srcObject = remoteStream;
      }
    });

    connectionRef.current = peer;
  }, []);

  const closeModalAndBeginCall = useCallback(() => {
    socketRef.current.emit("join-room", meetingId);

    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then((stream) => {
        streamRef.current = stream;
        setIsVideoEnabled(true);
        const audio = new Audio(join);
        audio.play();
        if (myVideoRef.current) {
          myVideoRef.current.srcObject = stream;
        }
      })
      .catch((error) => {
        console.error("Error accessing media devices:", error);
      });

    // Initialize the peer connection
    connectionRef.current = new Peer({ initiator: true });

    // Cleanup on component unmount
    return () => {
      if (connectionRef.current) {
        connectionRef.current.destroy();
        connectionRef.current = null;
      }
    };
  }, [meetingId]);

  const answerCall = useCallback((from: string, signal: any) => {
    if (!streamRef.current) {
      console.error("Stream is not available, retrying...");
      setTimeout(() => answerCall(from, signal), 1000); // Retry after 1 second
      return;
    }

    setCallAccepted(true);

    const peer = new Peer({
      initiator: false,
      trickle: false,
      stream: streamRef.current,
    });

    peer.on("signal", (data) => {
      setCallAccepted(true);
      socketRef.current.emit("answerCall", { signal: data, to: from });
    });

    peer.on("stream", (stream) => {
      console.log("Receiving remote stream");
      if (userVideoRef.current) {
        userVideoRef.current.srcObject = stream;
      }
    });

    peer.signal(signal);

    connectionRef.current = peer;
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setInviteModalOpen(true);
    setModalAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setInviteModalOpen(false);
    setModalAnchorEl(null);
  };

  useEffect(() => {
    mySocketIdRef.current = mySocketId;
  }, [mySocketId]);

  useEffect(() => {
    console.log("Attempting to connect to WebSocket server...");
    socketRef.current = io(
      // "https://us-central1-quickvideochat-23f18.cloudfunctions.net/api",
      // "https://api-ilhpn2lsdq-uc.a.run.app",
      "https://sockethandler-ilhpn2lsdq-uc.a.run.app",
      // "https://us-central1-quickvideochat-23f18.cloudfunctions.net/api",
      // "wss://us-central1-quickvideochat-23f18.cloudfunctions.net/api?EIO=4&transport=websocket",
      // "wss://api-ilhpn2lsdq-uc.a.run.app?EIO=4&transport=websocket",
      // wss://api-ilhpn2lsdq-uc.a.run.app/socket.io/?EIO=4&transport=websocket"

      {
        withCredentials: true,
        transports: ["websocket", "polling"],
      }
    );

    closeModalAndBeginCall();

    socketRef.current.on("callUser", ({ from, signal }) => {
      answerCall(from, signal);
    });

    socketRef.current.on("callAccepted", (signal) => {
      console.log("Call accepted");

      setCallAccepted(true);
      handleSignal(signal);
    });

    // play the sound when the user joins
    socketRef.current.on("userJoined", () => {
      console.log("I am alive");
      const audio = new Audio(join);
      audio.play();
    });

    socketRef.current.on("connect", () => {
      const socketId = socketRef.current.id;

      setMySocketId(socketId);
      socketRef.current.emit("ready-to-call", socketId);
      socketRef.current.emit("userJoined", meetingId);
    });

    socketRef.current.on("room-full", () => {
      alert("The room is full. Please try again later.");
      navigate("/");
    });

    socketRef.current.on("ready-to-call", (id: string) => {
      if (id !== mySocketIdRef.current) {
        callUser(id);
      }
    });

    socketRef.current.on("userJoined", () => {
      const audio = new Audio(join);
      audio.play();
    });

    socketRef.current.on("disconnect", () => {
      setCallAccepted(false);
      if (connectionRef.current) {
        connectionRef.current.destroy();
        connectionRef.current = null;
      }
      console.log("Socket disconnected");
    });

    socketRef.current.on("connect_error", (error) => {
      console.error("Socket connection error:", { error });
      console.log("DESC", error.description);
      console.log("MSG", error.message);
      console.log("CONTEXT", error.context);
    });

    socketRef.current.on("user_disconnect", (socketId) => {
      setCallAccepted(false);
      if (userVideoRef.current && userVideoRef.current.srcObject) {
        userVideoRef.current.srcObject = null;
      }
    });

    // Cleanup on component unmount
    return () => {
      socketRef.current.disconnect();
    };
  }, [answerCall, callUser, meetingId, navigate, closeModalAndBeginCall]);

  useEffect(() => {
    console.log("isVideoEnabled", isVideoEnabled);
    if (streamRef.current) {
      const videoTrack = streamRef.current.getVideoTracks()[0];
      if (videoTrack) {
        videoTrack.enabled = isVideoEnabled;
        setVideoEnabled(videoTrack.enabled);
        setIsVideoEnabled(videoTrack.enabled);
      }
    }
  }, [isVideoEnabled]);

  const handleSignal = (signal: any) => {
    if (connectionRef.current && !connectionRef.current.destroyed) {
      try {
        connectionRef.current.signal(signal);
      } catch (error) {
        console.error("Error signaling peer:", error);
      }
    } else {
      console.error("Cannot signal after peer is destroyed");
    }
  };

  const stopMyVideo = () => {
    if (streamRef.current) {
      const videoTrack = streamRef.current.getVideoTracks()[0];
      if (videoTrack) {
        videoTrack.enabled = !videoTrack.enabled;
        setVideoEnabled(videoTrack.enabled);
      }
    }
  };

  const stopMyAudio = () => {
    if (streamRef.current) {
      const audioTrack = streamRef.current.getAudioTracks()[0];
      if (audioTrack) {
        audioTrack.enabled = !audioTrack.enabled;
        setAudioEnabled(audioTrack.enabled);
      }
    }
  };

  const handleEndCall = () => {
    socketRef.current.emit("user_disconnect", meetingId);
    if (connectionRef.current) {
      connectionRef.current.destroy();
      connectionRef.current = null;
    }

    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
      streamRef.current = null;
    }

    if (myVideoRef.current) {
      myVideoRef.current.srcObject = null;
    }

    if (userVideoRef.current) {
      userVideoRef.current.srcObject = null;
    }

    setCallAccepted(false);
    navigate("/");
  };

  return (
    <>
      <div className="normal">
        <div className={"video-container"}>
          <div className="left-video">
            {isVideoEnabled && (
              <div className="video">
                <video ref={myVideoRef} autoPlay muted></video>
              </div>
            )}
          </div>

          <div className="right-video video-wrapper">
            {callAccepted ? (
              <div className="video">
                <video ref={userVideoRef} autoPlay></video>
              </div>
            ) : (
              <div>
                <img
                  className="video-off-raining"
                  src={raining}
                  alt="raining"
                />
              </div>
            )}
          </div>
        </div>
        <div className="meeting-controls">
          <div className="controls-wrapper">
            <button
              className={`controls--video-btn ${
                videoEnabled ? "enabled" : "disabled"
              }`}
              onClick={stopMyVideo}
            >
              {videoEnabled ? <DisableVideo /> : <EnableVideo />}
            </button>

            <button
              className={`controls--video-btn ${
                audioEnabled ? "enabled" : "disabled"
              }`}
              onClick={stopMyAudio}
            >
              {audioEnabled ? <DisableAudio /> : <EnableAudio />}
            </button>

            <button
              onClick={handleClick}
              className={`controls--video-btn enabled`}
            >
              <div>
                <PersonAddIcon fontSize="large" sx={{ color: "#040404" }} />
              </div>
            </button>
            <button
              onClick={handleEndCall}
              className={`controls--video-btn enabled`}
            >
              <div>
                <CallEndIcon fontSize="large" sx={{ color: "#EE4B2B" }} />
              </div>
            </button>
            {modalAnchorEl && (
              <InviteToMeetingModal
                open={inviteModalOpen}
                modalAnchorEl={modalAnchorEl}
                meetingId={meetingId}
                handleClose={handleClose}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Meeting;
