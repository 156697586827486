import { v4 as uuidv4 } from "uuid";

export const generateMeetingGuid = () => {
  const meetingId = uuidv4();
  console.log(meetingId);
  return meetingId;
};

// export const newMeeting = () => {
//   const meetingId = generateMeetingGuid();
//   // can i use navigate here?
// };
