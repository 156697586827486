import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./style.css";

export default function Start() {
  const location = useLocation();
  const navigate = useNavigate();
  const [meetingId, setMeetingId] = useState("");

  useEffect(() => {
    const meetingId = location.state.meetingId;

    setMeetingId(meetingId);
  }, [location.state.meetingId]);

  const handleCancel = () => {
    navigate("/");
  };

  const startMeeting = () => {
    navigate(`/meeting/${meetingId}`, { state: { meetingId } });
  };

  const copyMeeting = () => {
    const baseUrl = window.location.origin;
    navigator.clipboard.writeText(`${baseUrl}/meeting/${meetingId}`);
  };

  return (
    <div className="card">
      <div className="card-content">
        <div>Meeting ID: {meetingId}</div>
        <div className="card-buttons">
          <button onClick={startMeeting}>Start Meeting</button>
          <button onClick={copyMeeting}>Copy meeting</button>
        </div>
        <button onClick={handleCancel}>Cancel</button>
      </div>
    </div>
  );
}
